import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthWrapper from "wrappers/AuthWrapper";
import ScrollToTop from "components/ScrollToTop";
import { useAuth } from "utils/hooks/useAuth";

const Notifications = lazy(() => import("pages/Notifications"));
const Contacts = lazy(() => import("pages/Contact"));
const ContactDetail = lazy(
  () => import("pages/Contact/component/ContactDetail"),
);
const Stores = lazy(() => import("pages/Stores/StoreList"));
const StoreDetail = lazy(() => import("pages/Stores/StoreDetail"));
const ShipperList = lazy(() => import("pages/Shipper/ShipperList"));
const ShipperListRequest = lazy(
  () => import("pages/Shipper/ShipperListRequest"),
);
const ShipperDetail = lazy(() => import("pages/Shipper/ShipperDetail"));
const UserList = lazy(() => import("pages/Users/UserList"));
const UserDetail = lazy(() => import("pages/Users/UserDetail"));
const Login = lazy(() => import("pages/Login"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Staffs = lazy(() => import("pages/Staffs/StaffList"));
const StaffDetail = lazy(() => import("pages/Staffs/StaffDetail"));
const ViewsSetting = lazy(() => import("pages/View"));
const ShipperEdit = lazy(() => import("pages/Shipper/ShipperEdit"));
const StoreEdit = lazy(() => import("pages/Stores/StoreEdit"));
const Category = lazy(() => import("pages/MasterData/Category"));
const StaticPage = lazy(() => import("pages/MasterData/StaticPage"));
const VerifyCodeOtp = lazy(
  () => import("pages/Login/components/VerifyCodeOtp"),
);
const GetCodeOtp = lazy(() => import("pages/Login/components/GetCodeOtp"));
const NewPassword = lazy(() => import("pages/Login/components/NewPassword"));
const EditTerm = lazy(
  () =>
    import(
      "pages/MasterData/StaticPage/components/ListTerm/components/EditTerm"
    ),
);
const EditPolicy = lazy(
  () =>
    import(
      "pages/MasterData/StaticPage/components/ListPolicy/components/EditPolicy"
    ),
);
const EditCommerLaw = lazy(
  () =>
    import(
      "pages/MasterData/StaticPage/components/ListLaw/components/EditCommerLaw"
    ),
);
const EditFaq = lazy(
  () =>
    import("pages/MasterData/StaticPage/components/ListFaq/components/EditFaq"),
);

const NotificationManagement = lazy(
  () => import("pages/NotificationManagement"),
);

const NotificationManagementStore = lazy(
  () => import("pages/NotificationManagementStore"),
);

const NotificationManagementDetail = lazy(
  () => import("pages/NotificationManagementDetail"),
);
const LpVideoManager = lazy(() => import("pages/LpVideoManager"));

export default function RootWrapper() {
  const authContext = useAuth();
  return (
    <ScrollToTop>
      <Routes>
        <Route
          path="/login"
          element={authContext?.token ? <Navigate to="/" replace /> : <Login />}
        />
        <Route
          path="/forgot-password-verify-code"
          element={<VerifyCodeOtp />}
        />
        <Route path="/forgot-password-get-code" element={<GetCodeOtp />} />
        <Route path="/forgot-password" element={<NewPassword />} />

        <Route path="/" element={<AuthWrapper />}>
          {/* REVENUE */}
          <Route path="/dashboard" element={<Dashboard />} />
          {/* STAFF */}
          <Route path="/staffs" element={<Staffs />} />
          <Route path="/staffs/detail/:id" element={<StaffDetail />} />
          {/* USER */}
          <Route path="/users" element={<UserList />} />
          <Route path="/users/detail/:id" element={<UserDetail />} />
          {/* SHIPPER */}
          <Route path="/shippers" element={<ShipperList />} />
          <Route
            path="/shippers/request-shippers"
            element={<ShipperListRequest />}
          />
          <Route path="/shippers/add-shipper" element={<ShipperEdit />} />
          <Route path="/shippers/edit-shipper/:id" element={<ShipperEdit />} />
          <Route
            path="/shippers/detail-shipper/:id"
            element={<ShipperDetail />}
          />
          {/* STORE */}
          <Route path="/stores" element={<Stores />} />
          <Route path="/stores/add-store" element={<StoreEdit />} />
          <Route path="/stores/edit-store/:id" element={<StoreEdit />} />
          <Route path="/stores/detail-store/:id" element={<StoreDetail />} />
          {/* NOTIFICATION */}
          <Route path="/notifications" element={<Notifications />} />
          {/* CONTACT */}
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contacts/detail/:id" element={<ContactDetail />} />
          {/* VIEW */}
          <Route path="/view-setting" element={<ViewsSetting />} />
          {/* MASTER DATA */}
          <Route path="/master-data/category" element={<Category />} />
          <Route path="/master-data/static-page" element={<StaticPage />} />
          <Route
            path="/master-data/static-page/term-edit/:type"
            element={<EditTerm />}
          />
          <Route
            path="/master-data/static-page/policy-edit/:type"
            element={<EditPolicy />}
          />
          <Route
            path="/master-data/static-page/commercial-law-edit/:type"
            element={<EditCommerLaw />}
          />
          <Route
            path="/master-data/static-page/faq-edit/:type"
            element={<EditFaq />}
          />
          <Route
            path="/notification-management"
            element={<NotificationManagement />}
          />
          <Route
            path="/notification-management/store"
            element={<NotificationManagementStore />}
          />
          <Route
            path="/notification-management/store/:id"
            element={<NotificationManagementStore />}
          />
          <Route
            path="/notification-management/detail/:id"
            element={<NotificationManagementDetail />}
          />
          <Route path="/lp-video-manager" element={<LpVideoManager />} />
        </Route>
        {/* <Route path="access-denied" element={<AccessDenied />} />
      <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </ScrollToTop>
  );
}

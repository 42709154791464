export const listStaffKey = "listStaffKey";
export const listUserKey = "listUserKey";
export const listNotificationKey = "listNotificationKey";
export const revenueYearKey = "revenueYearKey";
export const accountYearKey = "accountYearKey";
export const listContactKey = "listContactKey";
export const revenueStoreKey = "revenueStoreKey";
export const listShipperKey = "listShipperKey";
export const listStoreKey = "listShipperKey";
export const listHistoryShipperKey = "listHistoryShipperKey";
export const listProductStoreKey = "listProductStoreKey";
export const listHistoryStoreKey = "listHistoryStoreKey";
export const listStatisticStoreKey = "listStatisticStoreKey";
export const listMasterCateStoreKey = "listCategoriesStoreKey";
export const listMasterCateWorkerKey = "listCategoriesWokerKey";
export const listMasterTermKey = "listMasterTermKey";
export const listMasterPolicyKey = "listMasterPolicyKey";
export const listMasterLawKey = "listMasterLawKey";
export const listMasterQaKey = "listMasterQaKey";
export const infoBadgeKey = "infoBadgeKey";
